import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hang Power Cleans 4×6\\@70%1RM`}</p>
    <p>{`Front Squat 3×6\\@70%1RM`}</p>
    <p>{`then,`}</p>
    <p>{`30-Power Cleans (115/75)`}</p>
    <p>{`400M Run`}</p>
    <p>{`25-Front Squats (115/75)`}</p>
    <p>{`400M Run`}</p>
    <p>{`20-Squat Cleans (115/75)`}</p>
    <p>{`400M Run`}</p>
    <p>{`for time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 7/29/19`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      